import { ErrorHandler, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import {  Observable, of } from 'rxjs';
import { catchError, map, mergeMap, retry, take, tap } from 'rxjs/operators';
import { composedData, gate, mergedGates, paymentCharge, paymnetdata, productData } from '../interfaces/mydata.interfaces';
import { GateSelectService } from './gate-select.service';
import {MatDialog} from '@angular/material/dialog';
import { ErrorComponent } from 'src/app/error/error.component';
@Injectable({
  providedIn: 'root'
})
export class MyapiService implements ErrorHandler {

  isPnet:boolean = false;
  glopalgate : Observable<gate[]> = of(environment.gates)
  constructor(private http: HttpClient,private selectini:GateSelectService,public dialog: MatDialog) { }
  handleError(error: any): void {
    console.log('error happend',error),
     this.openDialog()
  }
  /**
   * get paymnet methods allowed and charges for each
   * @param Curr user curruncy
   * @param pos Payment location
   * @param BN  product booking number
   * @param product flight|| hotels
   * @param sid  user search id
   * @param tok  transaction token 
   * @param mapImgs  function to add imgs to payment gatway opject
   * @returns observable :paymentCharge[]
   */
  getPaymentCharge(Curr: string, pos: string, BN: string, product: string, sid: string = null, tok: string = null): Observable<paymentCharge[]|mergedGates[]> {

    let api: string = `${environment.url.admin}${environment.Apis.getCharges}?UserCurrency=${Curr}&PaymentLocation=${pos}&HGNumber=${BN}&ProductType=flight&sid=${sid}&tok=${tok}`;
    return this.http.get<paymentCharge[]>(api).pipe(
      retry(3),map(v=>{console.log("the direct response",v);return this.mapGatesA(v,environment.gates)}) ,take(1) ,catchError(err => {throw err})
    )
    // .pipe(retry(3), take(1), map(v => return v),catchError(err => this.globalErr = err))
  }

  /**
   * 
   * @param HG  booking number used by boking engin
   * @param token token for this payment transaction
   * @param sid search id used by the user
   * @returns object of customer info and product fare
   */
  getProductInfo(HG: string, token: string, sid: string): Observable<productData> {
    let api: string = `${environment.url.prepay}${environment.Apis.getflight}?HG=${HG}&Tok=${token}&Sid=${sid}`;
    return this.http.get<productData>(api).pipe(retry(3), take(1),
      map(v => {
        if (!v) {
          throw 'empty value'
        }
        return v
      }), catchError(err => {throw err})
      )


  }

  /**
   * return payment gate checkout page and intiate payment process
   * @param hg booking number used by boking engin
   * @param SID search id used by the user
   * @param tok token for this payment transaction
   * @param paymnntMethod payment method selected by the user  like knet,card ,check etc
   * @param GatewayCharges charge of selected gatway 
   * @param GatewayType type of payment that user selected and offerd by the gatway like 
   * tapVisacredit ,tapMasterCeredit etc
   * @param src src of requst mob or web
   * @param payToken payment tok for the gatway optinal
   * @returns 
   */
  startPaymentProcess(hg: string, SID: string, tok: string, paymnntMethod: string, GatewayCharges: string, GatewayType: string, src: string = 'mop', payToken: string = '') {
    let api = `${environment.url.prepay}${environment.Apis.startPayment}?HG=${hg}&payToken=${payToken}&SId=${SID}&Tok=${tok}&paymentMethod=${paymnntMethod}&GatewayCharges=${GatewayCharges}&GatewayType=${GatewayType}`;
    console.log(api);
    let body = { HG: hg, Tok: tok, SId: SID, payToken: tok, paymentMethod: paymnntMethod, GatewayCharges: GatewayCharges, GatewayType: GatewayType }
    if (src === 'mop' || src === '' || !src) {
       if(paymnntMethod ==='pnetKnet' || paymnntMethod ==='PnetCC'){
         this.isPnet = true;
       }
      return this.http.get<paymnetdata>(api).pipe(retry(3), take(1),
        map((va) => {
          if (!va || va.Status != 0) {
            throw  'somthisng wrong with output';
          } else {
            console.log(va);
            return va.paymentResult.RedirectUrl
          }
        }), catchError(err => {throw err}))
    } else {
      if(paymnntMethod ==='pnetKnet' || paymnntMethod ==='PnetCC'){
        this.isPnet = true;
      }
      return this.http.post<paymnetdata>(environment.url.prepay + environment.Apis.startPayment, body).pipe(retry(3), take(1),
      map((va) => {
        if (!va || va.Status != 0) {
          throw 'somthisng wrong with output';
        } else {
          return va.paymentResult.RedirectUrl
        }
      }), catchError(err => {throw err}))
    }


  }

 /**
  * 
  * @param x array of payment charge
  * @param y array of payment gate with logos
  * @returns merging the payment charges with imgs and api info
  */
  mapGatesA(x:paymentCharge[],y:gate[]):mergedGates[]
  {
    // let y:gate[] = environment.gates;
    let mergedA: mergedGates[]=[];
    x.forEach(
      (v)=>{
        let _y = y.filter((val)=> val.GatewayType.trim().toLowerCase() ===  v.PaymentMethod.trim().toLowerCase())[0]
        console.log('map gates response',_y)
        let merged:mergedGates ={
          cardImg:_y.cardImg,
          GatewayType:v.PaymentMethod,
          Currency:v.Currency,
          Amount:v.Amount,
          PaymentMethod:_y.PaymentMethod
        }
        mergedA.push(merged)
      }
    )
    return mergedA
  }

  /**
   * 
   * @param HG booking number
   * @param token transaction token
   * @param sid  customer search id
   * @returns composed result of payment gate ways and product data
   */
  composedData(HG: string, token: string, sid: string):Observable<composedData> {
      return this.getProductInfo(HG,token,sid).pipe(
        tap(v=>console.log(v)),
        mergeMap(
          (va=>this.getPaymentCharge(va.PaymentFareDetails.CustomerPaymentCurrency,va.paymentResult.PaymentLocation,HG,va.ProductType,sid,token).pipe(
            map((payment)=> {
              console.log(payment);
              let composed:composedData = {
                product:va,
                payment:payment
            
              };
              let inial = payment[0]
              this.selectini.select.next(inial);
              console.log('initial', inial);
              return composed
            })
          ))
        )
      )
  }
  openDialog() {
    this.dialog.open(ErrorComponent,{
      data: { url: environment.origin },
    });
  }
}
